@import '~scss/variables';

.Transactions {
    position: relative;
    z-index: 11;

    @include ipad {
        background: #f8fafd;
    }

    &.show {
        .TransactionsCardBlock {
            transform: translateY(0%);
            opacity: 1;
        }

        .TransactionsDetails {
            opacity: 1;
        }
    }
    &.hide {
        .TransactionsCardBlock {
            transform: translateY(-20%);
            opacity: 0;
        }

        .TransactionsDetails {
            opacity: 0;
        }
    }

    &Content {
        position: sticky;
        top: 0;
        left: 0;
        max-width: 100%;
        width: 1440px;
        padding: 40px 150px;
        height: 100vh;
        display: flex;
        margin: 0 auto;
        align-items: center;

        @include ipad {
            position: static;
            top: unset;
            left: unset;
            height: unset;
            padding: 48px 16px;

            .TransactionsCardBlock {
                transform: translateY(0%);
                opacity: 1;
            }

            .TransactionsDetails {
                opacity: 1;
            }
        }

        @include landscape {
            flex-direction: column;
            align-items: stretch;
            padding: 40px 16px;
        }
    }

    &CardBlock {
        flex-basis: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        transition: 0.3s;
        transition-delay: 0.15s;
        opacity: 0;
        transform: translateY(20%);

        @include ipad {
            position: relative;
            justify-content: center;
            flex-direction: column;
        }

        @include landscape {
            margin-bottom: 40px;
        }
    }

    &Card {
        background: #ffffff;
        box-shadow: 0px 24px 50px rgba(107, 132, 173, 0.07), 0px 3px 6px rgba(107, 132, 173, 0.1);
        border-radius: 17px;
        height: 272px;
        padding: 24px;
        width: 404px;
        display: flex;
        flex-direction: column;

        @include ipad {
            width: 288px;
            height: 182px;
            padding: 10px;
        }

        &Tab {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &Item {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }

            &Chart {
                width: 106px;
                height: 106px;

                @include ipad {
                    height: 84px;
                    width: 84px;
                }
            }

            &Title {
                margin: 20px 0 0;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                color: #444649;

                @include ipad {
                    margin-top: 0;
                }
            }

            &Value {
                margin: 0;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                color: #b2b4b7;

                @include ipad {
                    margin-top: 2px;
                }
            }
        }

        &Content {
            flex-grow: 1;
            position: relative;

            & > * {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                transition: 0.2s;

                &.active {
                    opacity: 1;
                }
            }
        }
    }

    &Details {
        max-width: 430px;
        flex-basis: 50%;
        transition: 0.3s;
        opacity: 0;

        @include landscape {
            max-width: 100%;
            align-self: stretch;
        }

        h2 {
            white-space: pre-wrap;
            font-weight: bold;
            font-size: 46px;
            line-height: 120%;
            margin: 0 0 24px;
            color: #2f3a47;

            @include ipad {
                font-size: 38px;
                line-height: 100%;
                margin-bottom: 16px;
            }

            @include landscape {
                white-space: unset;
            }

            b {
                color: #0066ff;
            }
        }

        p {
            margin: 0 0 32px;
            font-size: 16px;
            line-height: 155%;
            max-width: 430px;
            color: #738192;

            @include ipad {
                margin-bottom: 24px;
            }
            @include landscape {
                max-width: 100%;
            }
        }

        a {
            @include landscape {
                width: 100%;
            }
        }
    }
}

.Tabs {
    display: flex;
    align-items: center;
    padding: 3px;
    background: #edeeee;
    border-radius: 8px;
    transition: 0.2s;
    position: relative;
    margin-bottom: 16px;

    @include ipad {
        padding: 1px;
        margin-bottom: 16px;
    }

    &Bg {
        position: absolute;
        top: 3px;
        left: 3px;
        height: calc(100% - 3px * 2);
        width: calc((100% - 3px * 2) / 2);
        background: #ffffff;
        border-radius: 6px;
        transition: inherit;
        z-index: 1;

        &.active {
            transform: translateX(100%);
        }
    }

    & > *:not(&Bg) {
        flex-basis: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 6px;
        z-index: 2;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #353c45;
        opacity: 0.4;
        transition: inherit;

        @include ipad {
            white-space: nowrap;
            font-size: 10px;
            line-height: 14px;
        }

        &.active {
            opacity: 1;
        }
    }
}

.PhoneImg {
    display: inline-flex;
    width: 252px;
    height: auto;
    max-height: 100%;
}
