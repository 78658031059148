@import '~scss/variables';

.Loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    overflow: hidden;

    $base-bubble-anim-duration: 1.5s;
    $slowed-bubble-anim-duration: 8s;
    // $slowed-bubble-anim-duration: $base-bubble-anim-duration * 2;
    @mixin setDelay($duration) {
        .LoaderLeftSide .LoaderBubble {
            &:nth-child(1) {
                animation-delay: $duration * 0;
            }
            &:nth-child(2) {
                animation-delay: $duration * 0.6;
            }
            &:nth-child(1) {
                animation-delay: $duration * 0.33;
            }
        }
        .LoaderRightSide .LoaderBubble {
            &:nth-child(1) {
                animation-delay: $duration * 0.8;
            }
            &:nth-child(2) {
                animation-delay: $duration * 0.45;
            }
            &:nth-child(1) {
                animation-delay: $duration * 0.15;
            }
        }
    }

    @keyframes toTop {
        0% {
            transform: translateY(100%);
        }
        100% {
            transform: translateY(-100vh);
        }
    }

    &LeftSide,
    &RightSide {
        flex-basis: 50%;
        display: flex;
        align-items: flex-end;
        justify-content: space-around;
        transition: 1.5s;
    }

    &Bubble {
        flex-shrink: 0;
        background: #ffffff;
        box-shadow: 0px 1px 1px rgba(107, 132, 173, 0.06), 0px 3px 6px rgba(107, 132, 173, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        transform: translateY(100%);
        border-radius: 50%;
        animation: toTop $base-bubble-anim-duration ease-in-out infinite both;

        img,
        svg {
            width: 24px;
            height: auto;
        }
    }
    @include setDelay($base-bubble-anim-duration);

    &.loaded {
        .LoaderLeftSide,
        .LoaderRightSide {
            flex-basis: calc((100% - 700px) / 2);

            @include landscape {
                flex-basis: 50%;
            }
        }

        @include setDelay($slowed-bubble-anim-duration);

        .LoaderBubble {
            animation-duration: $slowed-bubble-anim-duration;
        }
    }

    &Progress {
        position: absolute;
        top: 50%;
        font-weight: 500;
        font-size: 24px;
        line-height: 124%;
        color: #2f3a47;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.PreviewScreen {
    position: relative;
    z-index: 5;
    flex-grow: 1;
    width: 100%;
    transition: 0.5s;
    min-height: 100vh;

    @include ipad {
        padding-top: 384px;
        padding-right: 16px;
        padding-left: 16px;
        padding-bottom: 48px;
        min-height: unset;
        background: #f8fafd;
    }

    @include landscape {
        padding-bottom: 40px;
    }

    // Hide, when element is scrolled
    &.hide {
        opacity: 0;
        transform: translateY(-10%);
    }

    &Content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100vh;
        position: relative;
        // By default element is hidden
        opacity: 0;
        transition: inherit;
        transform: translateY(-20%);
        // Visible part of phone
        padding-bottom: 400px;
        // Header
        padding-top: 94px;

        @include ipad {
            align-items: stretch;
            padding-top: 0;
            padding-bottom: 0;
            height: unset;
            max-width: 528px;
            margin: 0 auto;
        }

        // On loading end - show content
        &.show {
            opacity: 1;
            transform: translateY(0);
        }
    }

    &BtnGroup {
        display: flex;
        align-items: center;
        justify-content: center;

        a,
        button {
            margin-right: 8px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    h1 {
        margin: 0 0 30px;
        white-space: pre-wrap;
        font-weight: bold;
        font-size: 58px;
        line-height: 124%;
        text-align: center;
        color: #2f3a47;

        position: relative;

        @include ipad {
            font-size: 40px;
            line-height: 124%;
            letter-spacing: -0.03em;
        }

        @include phone {
            text-align: left;
        }

        span {
            color: $color-blue;
        }
    }
}

.PhoneImg {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translate(-50%, 0);
    height: auto;
    width: 239px;
}

.BtnDownload {
    background: #0066ff;
    box-shadow: 0px 4px 16px rgba(0, 102, 255, 0.21);
    border-radius: 75px;
    width: 100%;
    max-width: 528px;

    @include hover {
        background: #2f3a47;
    }

    &:active {
        background: #1c242d;
    }

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 24px;
        position: relative;
        font-weight: 600;
        white-space: nowrap;
        font-size: 14px;

        line-height: 148%;
        color: #ffffff;

        svg {
            flex-shrink: 0;
            margin-right: 8px;
        }
    }
}
