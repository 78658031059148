@import '~scss/variables';
.Features {
    position: relative;
    background: #ffffff;
    height: 100vh;
    overflow: hidden;
    z-index: 9;

    @include ipad {
        height: unset;
        padding: 48px 0;
        background: #f9fbfe;
    }

    @include landscape {
        padding: 48px 0;
    }

    &Wrapper {
        width: 100%;
        height: 100%;

        @include ipad {
            height: unset;
            width: unset;
        }
    }

    &Container {
        margin: 0 auto;
        max-width: 1440px;
        padding: calc((70px + 100vh - 566px) / 2) 150px 0;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @include ipad {
            padding: unset;
            justify-content: center;
        }

        @include tablet {
            flex-direction: column;
            align-items: stretch;
        }
    }

    &AppScreen {
        position: relative;
        flex-basis: 280px;
        height: 566px;
        flex-shrink: 0;
        margin-right: 24px;

        @include ipad {
            height: 296px;
            margin-right: 0;
            flex-basis: 324px;
        }

        @include tablet {
            margin-bottom: 24px;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            max-width: 100%;
            height: auto;
            transition: 0.2s;

            @include ipad {
                height: 100%;
                left: 50%;
                transform: translateX(-50%);
                width: auto;
            }
        }
    }

    &Details {
        padding-top: 105px;

        @include ipad {
            padding-top: 0;
        }
    }

    &TabsContent {
        transition: 0.3s;
        opacity: 0;
        position: absolute;
        pointer-events: none;
        top: 0;
        left: 0;

        @include ipad {
            width: 100%;
            padding: 0 8px;
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        @include tablet {
            text-align: center;
            margin: 0 auto;
            padding: 0 16px;
            left: 50%;
            transform: translateX(-50%);
        }

        @include landscape {
            text-align: left;
        }

        &Wrapper {
            position: relative;
        }

        &.active {
            opacity: 1;
            pointer-events: unset;
            z-index: 2;
        }

        h2 {
            font-weight: bold;
            margin: 0 0 16px;
            font-size: 38px;
            line-height: 120%;
            color: #2f3a47;
        }

        p {
            font-size: 16px;
            line-height: 155%;
            max-width: 430px;
            color: #738192;
            margin: 0 0 24px;

            @include ipad {
                flex-grow: 1;
            }
            @include tablet {
                margin-right: auto;
                margin-left: auto;
            }

            @include landscape {
                margin-right: 0;
                margin-left: 0;
            }
        }
    }

    &BtnGroup {
        display: flex;

        @include tablet {
            justify-content: center;
        }

        @include landscape {
            flex-direction: column;
            align-items: stretch;
        }

        & > a {
            margin-right: 10px;
            @include landscape {
                margin-right: 0;
                margin-bottom: 8px;
            }

            &:last-child {
                margin-right: 0;

                @include landscape {
                    margin-bottom: 0;
                }
            }

            span {
                white-space: nowrap;
            }
        }
    }
}

.Progress {
    width: 100%;
    height: 100%;
    transform: translateX(-101%);
    display: flex;
    &Bar {
        position: absolute;
        animation-timing-function: linear;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        height: 2px;
        overflow: hidden;
    }
}

.Tabs {
    background: #ffffff;
    box-shadow: 0px 24px 50px rgba(107, 132, 173, 0.07), 0px 3px 6px rgba(107, 132, 173, 0.1);
    border-radius: 7px;
    display: flex;
    overflow: hidden;
    margin-bottom: 40px;
    position: relative;
    z-index: 2;

    @include ipad {
        padding: 0 8px 16px;
        overflow: auto;
        background: transparent;
        box-shadow: none;
        margin-bottom: 24px;

        @include disableScrollbar;
    }

    @include tablet {
        margin-right: auto;
        margin-left: auto;
        width: fit-content;
        max-width: 100%;
    }

    li {
        border-right: 1px solid #ebeff3;
        font-weight: bold;
        font-size: 12px;
        line-height: 150%;
        cursor: pointer;
        transition: 0.2s;

        .TabWrapper {
            border-radius: inherit;
            overflow: hidden;
            padding: 14px;
            display: flex;
            align-items: center;
            position: relative;
            justify-content: flex-start;
        }

        svg {
            margin-right: 14px;
            transition: inherit;
            flex-shrink: 0;

            path {
                transition: inherit;
            }
        }

        @include ipad {
            background: #ffffff;
            box-shadow: 0px 3px 6px rgba(107, 132, 173, 0.1);
            border: none;
            border-radius: 7px;
            width: fit-content;
            margin-right: 8px;
            white-space: nowrap;
            overflow: unset;
            &:last-child {
                margin-right: 0;
            }
        }

        &:first-child {
            color: #0066ff;
            .Progress {
                background-color: #0066ff;
            }
        }

        &:nth-child(2) {
            color: #ff7e8a;
            .Progress {
                background-color: #ff7e8a;
            }
        }

        &:nth-child(3) {
            color: #5ce1a6;
            .Progress {
                background-color: #5ce1a6;
            }
        }

        &:last-child {
            border-right: none;
        }

        &:not(.active) {
            color: #b8c6db;

            @include hover {
                background: #f8faff;
            }

            svg {
                path {
                    &:first-child {
                        fill: #f0f4f9;
                    }

                    &:not(:first-child) {
                        fill: #b8c6db;
                    }
                }
            }

            &:active {
                background: #f8faff;
                color: #8494ab;
                svg {
                    path {
                        &:first-child {
                            fill: #dfe6f0;
                        }

                        &:not(:first-child) {
                            fill: #8494ab;
                        }
                    }
                }
            }
        }

        @keyframes fill {
            0% {
                transform: translateX(-100%);
            }
            100% {
                transform: translateX(0%);
            }
        }

        &.active {
            cursor: default;
            .Progress {
                animation-name: fill;
            }
        }
    }
}
