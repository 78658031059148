@import '~scss/variables';

.Component {
    display: flex;
    flex-direction: column;
    background: #fff;
    min-height: 100.1vh;
    position: relative;
}

.App {
    transition: 0.5s;
    max-width: 100%;
    // By default element is hidden
    opacity: 0;

    // On loading end - show content
    &.show {
        opacity: 1;
    }

    &.hide {
        opacity: 0;
        transform: translateX(-20%);
    }

    &.remove {
        display: none;
    }

    &Wrapper {
        height: 0;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 5;

        @include landscape {
            position: static;
        }
    }
}
