.Component {
    margin: 0;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: left;

    & > span {
        transition: inherit;
        display: inherit;
        border-radius: inherit;
        justify-content: inherit;
        width: 100%;
        align-items: inherit;
    }

    &:focus > span {
        box-shadow: 0px 0px 0px 2px #0066ff;
    }
}
