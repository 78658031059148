@import '~scss/variables';

.Component {
    padding-top: 54px;
    position: relative;
    background: #1c242e;
    z-index: 8;

    @include ipad {
        padding-top: 48px;
    }
    @include landscape {
        padding-top: 40px;
    }
}

.Container {
    flex-grow: 1;
    margin: 0 auto;
    max-width: 1440px;
    padding: 0 40px;

    @include ipad {
        padding: 0 16px;
    }
}

.Main {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 40px;

    @include tablet {
        flex-direction: column;
    }
}

.Nav {
    @include tablet {
        margin-bottom: 32px;
    }

    nav {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @include tablet {
            margin-top: 32px;
        }

        a {
            margin-bottom: 6px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.Address {
    address {
        font-size: 14px;
        line-height: 140%;
        text-align: right;
        color: #ffffff;
        opacity: 0.4;
        font-style: normal;
        white-space: pre-wrap;

        @include tablet {
            white-space: normal;
            text-align: left;
        }

        span {
            margin-top: 10px;
            display: block;
        }
    }
}

.Social {
    @include tablet {
        margin-bottom: 32px;
    }

    p {
        font-weight: bold;
        font-size: 14px;
        line-height: 140%;
        color: rgba(255, 255, 255, 0.47);
        margin: 0 0 16px;
        white-space: pre-wrap;

        @include landscape {
            white-space: unset;
        }
    }

    ul {
        display: flex;
        align-items: center;

        & > li {
            margin-right: 8px;

            &:last-child {
                margin-right: 0;
            }

            a {
                background: rgba(255, 255, 255, 0.08);
                border-radius: 12px;

                &.instagram {
                    @include hover {
                        background: #ff6f01;
                    }

                    &:active {
                        background: rgba(#ff6f01, 0.8);
                    }
                }

                &.fb {
                    @include hover {
                        background: #4267b2;
                    }

                    &:active {
                        background: rgba(#4267b2, 0.8);
                    }
                }

                &.telegram {
                    @include hover {
                        background: #0088cc;
                    }

                    &:active {
                        background: rgba(#0088cc, 0.8);
                    }
                }

                &.youtube {
                    @include hover {
                        background: #ff0000;
                    }

                    &:active {
                        background: rgba(#ff0000, 0.8);
                    }
                }

                &.twitter {
                    @include hover {
                        background: #1da1f2;
                    }

                    &:active {
                        background: rgba(#1da1f2, 0.8);
                    }
                }

                & > span {
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }
}

.CardGroup {
    margin-bottom: 40px;
    display: flex;

    @include ipad {
        overflow: hidden;
        margin-bottom: 32px;
    }
    @include tablet {
        flex-direction: column;
    }
}

.BtnGroup {
    width: 100%;
    display: flex;

    @include tablet {
        flex-direction: column;
        align-items: stretch;
    }

    & > a {
        margin-right: 10px;

        @include ipad {
            flex-grow: 1;
        }

        @include tablet {
            margin-right: 0;
            margin-bottom: 8px;
        }

        &:last-child {
            margin-right: 0;

            @include tablet {
                margin-bottom: 0;
            }
        }
    }
}

.Card {
    flex-grow: 1;
    height: 415px;
    background: #2e363f;
    border-radius: 12px;
    padding: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    overflow: hidden;
    flex-basis: 50%;
    min-width: 0;
    margin-right: 20px;

    @include ipad {
        height: unset;
        padding: 255px 16px 16px;
    }

    @include tablet {
        height: unset;
        margin-right: 0;
        margin-bottom: 16px;
    }

    & > *:not(&Img) {
        position: relative;
    }

    &:last-child {
        margin-right: 0;

        @include tablet {
            margin-bottom: 0;
        }
    }

    &Img {
        position: absolute;
        z-index: 0;
    }

    &_app &Img {
        height: 364px;
        width: auto;
        right: 0;
        bottom: 0;
        transform: translateX(30%);

        @include ipad {
            bottom: unset;
            height: 277px;
            top: 60px;
            right: unset;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &_token &Img {
        height: 100%;
        width: auto;
        right: 0;
        bottom: 0;
        transform: translateX(30%);

        @include ipad {
            bottom: unset;
            height: 277px;
            top: 0;
            right: unset;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &_listfamily &Img {
        height: 100%;
        width: auto;
        right: 0;
        bottom: -24px;
        transform: translateX(30%);

        @include ipad {
            bottom: unset;
            height: 207px;
            top: 24px;
            right: unset;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    h3 {
        font-weight: bold;
        font-size: 40px;
        line-height: 124%;
        color: #f7f9fc;
        margin: 0 0 16px;
        white-space: pre-wrap;

        @include ipad {
            font-size: 32px;
            line-height: 124%;
        }
    }

    p {
        font-size: 16px;
        line-height: 155%;
        white-space: pre-wrap;
        color: #738192;
        margin: 0 0 20px;

        @include ipad {
            white-space: unset;
            margin-bottom: 24px;
        }
    }
}

.SubBlock {
    padding: 16px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.04);

    @include ipad {
        padding: 32px 0 48px;
    }

    @include landscape {
        padding-bottom: 40px;
    }

    &Container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include ipad {
            flex-direction: column;
            align-items: flex-start;
        }

        p {
            margin: 0;
            font-size: 14px;
            line-height: 140%;
            color: rgba($color: #ffffff, $alpha: 0.4);

            @include ipad {
                margin-bottom: 16px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            a {
                font-size: 14px;
                line-height: 19px;
                font-weight: bold;
                margin-left: 2px;
                color: #ffffff;
            }
        }
    }
}
