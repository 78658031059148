@import '~scss/variables';

.Component {
    border-radius: 10px;
    font-weight: bold;
    font-size: 18px;
    line-height: 124%;
    letter-spacing: -0.03em;

    & > span {
        padding: 6px 11px 7px 13px;
        border: 1px solid transparent;
    }

    small {
        display: block;
        font-weight: 500;
        font-size: 12px;
        line-height: 124%;
        letter-spacing: -0.03em;
    }

    img {
        margin-right: 8px;
    }

    &.dark {
        color: #fff;
        & > span {
            background: rgba(#fff, 0.1);
            // border-color: rgba(#fff, 0.1);
        }
        small {
            color: rgba(#fff, 0.4);
        }

        @include hover {
            & > span {
                background: rgba(#fff, 0.2);
                // border-color: rgba(#fff, 0.2);
            }
        }

        &:active > span {
            background: rgba(#fff, 0.3);
            // border-color: rgba(#fff, 0.3);
        }
    }

    &.light {
        color: #2f3a47;
        & > span {
            background: #fff;
            border-color: rgba(#2f3a47, 0.1);
        }
        small {
            color: rgba(#2f3a47, 0.4);
        }

        @include hover {
            & > span {
                background: #f8faff;
            }
        }

        &:active > span {
            background: #eff3fc;
        }
    }
}
