@import '~scss/variables';

.Chat {
    position: relative;
    z-index: 15;

    @include ipad {
        padding: 48px 40px 48px 16px;
        background: #f7f4ec;
    }

    @include landscape {
        padding: 40px 16px;
    }

    &Content {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        padding: 0 40px;
        display: flex;
        align-items: center;
        overflow: hidden;
        justify-content: center;

        @include ipad {
            position: static;
            padding: 0;
            top: unset;
            left: unset;
            overflow: unset;
            justify-content: center;
            height: unset;
            flex-direction: row-reverse;
        }

        @include landscape {
            flex-direction: column;
        }
    }

    &Title {
        font-weight: bold;
        font-size: 66px;
        line-height: 114.5%;
        color: #3f3a27;
        white-space: pre-wrap;
        margin: 0 0 28px;

        @include ipad {
            font-size: 38px;
            line-height: 100%;
            margin-bottom: 16px;
        }

        @include landscape {
            white-space: initial;
        }

        span {
            color: #ff7411;
        }
    }

    &BtnGroup {
        display: flex;

        @include tablet {
            flex-direction: column;
            align-items: stretch;
        }

        & > a {
            margin-right: 10px;

            @include ipad {
                width: 240px;
            }

            @include tablet {
                margin-right: 0;
                margin-bottom: 8px;
            }

            @include landscape {
                width: unset;
            }

            &:last-child {
                margin-right: 0;

                @include tablet {
                    margin-bottom: 0;
                }
            }
        }
    }

    &RunningText {
        position: absolute;
        top: 50%;
        font-weight: bold;
        font-size: 150px;
        line-height: 114.5%;
        white-space: nowrap;
        color: #3f3a27;
        z-index: 1;
        left: 0;
        margin: 0;

        @include ipad {
            display: none;
        }
    }

    &LeftSide {
        flex-basis: 430px;
        opacity: 0;
        transition: 0.2s;
        transform: translateY(-20%);

        .Description {
            display: none;

            @include ipad {
                display: block;
            }
        }

        @include ipad {
            opacity: 1;
            transform: none;
            flex-basis: unset;
            margin-right: 80px;
        }

        @include landscape {
            width: 100%;
            margin-right: 0;
        }
    }

    &RightSide {
        flex-basis: 430px;
        opacity: 0;
        transition: 0.2s;
        transform: translateY(20%);

        @include ipad {
            display: none;
        }
    }

    &LeftSide.show,
    &RightSide.show {
        opacity: 1;
        transform: none;
    }
}

.Description {
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    margin: 0;
    color: #3f3a27;
    max-width: 430px;

    @include ipad {
        font-size: 16px;
        line-height: 155%;
        margin-bottom: 24px;
    }

    @include landscape {
        max-width: 100%;
    }
}

.App {
    position: relative;
    z-index: 2;
    margin-right: 70px;
    margin-left: 70px;
    width: 280px;
    flex-shrink: 0;
    height: 566px;

    @include ipad {
        display: none;
    }

    & > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: 0.15s;

        &:first-child {
            opacity: 1;
        }
    }

    &Lock {
        position: absolute;
        bottom: 40px;
        right: -75px;
        z-index: 5;
        width: 162px;
        height: 162px;
    }
}

.Example {
    align-self: center;
    position: relative;

    img {
        width: 284px;
        max-height: 100%;
    }

    &Messages {
        position: absolute;
        width: calc(100% + 48px);
        bottom: 50%;
        display: flex;
        transform: translateY(50%);
        flex-direction: column;
        left: -24px;

        @include landscape {
            width: 100%;
            transform: none;
            left: 0;
            bottom: 16px;
        }

        p {
            border-radius: 16px;
            padding: 8px 10px;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            box-shadow: 0px 24px 50px rgba(107, 132, 173, 0.07), 0px 3px 6px rgba(107, 132, 173, 0.1);
            margin: 0 0 8px;
            width: fit-content;
            max-width: calc(100% - 40px);

            &:last-child {
                margin-bottom: 0;
            }

            &:nth-child(even) {
                border-top-left-radius: 0;
                color: #233a55;
                background: #fff;
            }

            &:nth-child(odd) {
                background: #233a55;
                color: #fff;
                align-self: flex-end;
                border-top-right-radius: 0;
            }

            time {
                display: block;
                text-align: right;
                font-size: 14px;
                line-height: 17px;
                opacity: 0.4;
            }
        }
    }
}
