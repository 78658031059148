@import '~scss/variables';

.Component {
    border-radius: 10px;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    & > span {
        padding: 12px 24px;
    }

    &.blue {
        color: #f4f7fb;
        background: #0066ff;
        @include hover {
            background: #2f3a47;
        }

        &:active {
            background: #1c242d;
        }

        &:disabled {
            background: #0066ff;
            opacity: 0.2;
        }

        &:focus > span {
            box-shadow: 0px 0px 0px 2px #2f3a47;
        }
    }
    &.light-blue {
        color: #f4f7fb;
        background: #3593f3;
        @include hover {
            background: #2f3a47;
        }

        &:active {
            background: #1c242d;
        }

        &:disabled {
            background: #3593f3;
            opacity: 0.2;
        }

        &:focus > span {
            box-shadow: 0px 0px 0px 2px #2f3a47;
        }
    }

    &.secondary {
        color: #3f3a27;
        background: rgba(47, 58, 71, 0.1);
        @include hover {
            background: #2f3a47;
            color: #ffffff;
        }

        &:active {
            background: #1c242d;
            color: #ffffff;
        }

        &:disabled {
            color: #3f3a27;
            background: rgba(47, 58, 71, 0.1);
            opacity: 0.2;
        }
    }

    &.orange {
        color: #f4f7fb;
        background: #ff7411;

        @include hover {
            background: #2f3a47;
        }

        &:active {
            background: #1c242d;
        }

        &:disabled {
            background: #ff7411;
            opacity: 0.2;
        }
    }
    &.dark {
        color: #fff;
        background: rgba(#fff, 0.1);

        @include hover {
            background: rgba(#fff, 0.2);
        }

        &:active {
            background: rgba(#fff, 0.3);
        }
    }

    &.link {
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        border-radius: 7px;
        color: #ffffff;

        span {
            padding: 0 4px;

            @include ipad {
                padding: 0;
            }
        }

        @include hover {
            text-decoration: underline;
        }

        &:active {
            color: rgba(#fff, 0.6);
            text-decoration: underline;
        }

        &:disabled {
            text-decoration: none;
            color: rgba(#fff, 0.2);
        }
    }
}
