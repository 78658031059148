/*	COLORS */
$color-black: #2f3a47;
$color-black-lighten: #39485e;
$color-blue: #0066ff;

$breakpoint-desktop: 1440px;
$breakpoint-laptop: 1280px;
$breakpoint-ipad: 1024px;
$breakpoint-tablet: 768px;
$breakpoint-phone: 576px;

@mixin laptop {
    @media (max-width: $breakpoint-desktop - 0.2px) {
        @content;
    }
}

@mixin ipad {
    @media (max-width: $breakpoint-laptop - 0.2px) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: $breakpoint-ipad - 0.2px) {
        @content;
    }
}

@mixin landscape {
    @media (max-width: $breakpoint-tablet - 0.2px) {
        @content;
    }
}

@mixin phone {
    @media (max-width: $breakpoint-phone - 0.2px) {
        @content;
    }
}

@mixin container {
    max-width: $breakpoint-desktop;
    width: 100%;
    padding-right: 100px;
    padding-left: 100px;
    margin-left: auto;
    margin-right: auto;

    @include laptop {
        padding-right: 70px;
        padding-left: 70px;
    }

    @include tablet {
        padding-right: 60px;
        padding-left: 60px;
    }

    @include landscape {
        padding-right: 40px;
        padding-left: 40px;
    }

    @include phone {
        padding-right: 20px;
        padding-left: 20px;
    }
}

/* FONTS */
$font-face: 'Manrope', sans-serif;

$font-size-m: 16px;
$font-size-s: 14px;

$font-weight-bold: 700;
$font-weight-semibold: 600;
$font-weight-regular: normal;

@mixin font-m-bold {
    font-size: $font-size-m;
    font-weight: $font-weight-bold;
    line-height: 150%;
}

@mixin font-s-bold {
    font-size: $font-size-s;
    font-weight: $font-weight-bold;
    line-height: 150%;
}

@mixin font-s-semibold {
    font-size: $font-size-s;
    font-weight: $font-weight-semibold;
    line-height: 150%;
}

@mixin hover {
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            @content;
        }
    }
}

@mixin disableScrollbar {
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}
