@import '~scss/variables';

.Component {
    flex-grow: 1;
    background: #f8fafd;
    padding: 100px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include tablet {
        flex-direction: column;
    }

    & > img {
        max-width: 405px;
        height: auto;
        width: 100%;
        margin-right: 100px;

        @include tablet {
            margin-right: 0;
            margin-bottom: 40px;
        }
    }
}

.Details {
    @include tablet {
        text-align: center;

        br {
            display: none;
        }
    }

    code {
        font-weight: bold;
        font-size: 12px;
        line-height: 150%;
        color: #0066ff;
        text-transform: uppercase;
        font-family: inherit;
        display: block;
    }

    h1 {
        font-weight: bold;
        font-size: 58px;
        line-height: 124%;
        letter-spacing: -0.03em;
        color: #363642;
        margin: 0 0 8px;

        @include tablet {
            font-size: 38px;
            line-height: 124%;
        }
    }

    p {
        font-size: 16px;
        line-height: 155%;
        color: #738192;
        margin: 0 0 16px;
    }

    a {
        @include tablet {
            max-width: 288px;
            width: 100%;
        }
    }
}
