@import '~scss/variables';

.Advantage {
    position: relative;
    width: 100%;
    z-index: 4;

    @include ipad {
        background: #edeef7;
        padding: 48px 0 43px;

        &::after {
            content: '';
            height: 100%;
            z-index: 100;
            position: absolute;
            top: 0;
            left: 0;
            width: calc((100% - 280px) / 2 / 2);
            background: linear-gradient(90deg, #edeef7 0%, rgba(237, 238, 247, 0) 97.7%);

            @include landscape {
                display: none;
            }
        }

        &::before {
            content: '';
            height: 100%;
            z-index: 100;
            position: absolute;
            top: 0;
            right: 0;
            width: calc((100% - 280px) / 2 / 2);
            background: linear-gradient(270deg, #edeef7 -4.05%, rgba(237, 238, 247, 0) 100%);

            @include landscape {
                display: none;
            }
        }
    }

    @include landscape {
        padding: 40px 0 35px;
    }

    &Content {
        overflow: hidden;
        width: 100%;
        height: 100vh;
        display: none;

        @include ipad {
            height: unset;

            position: static;
        }

        &.sticky {
            top: 0;
            left: 0;
            position: sticky;
            display: block;
        }
    }

    &Tag {
        background: $color-black-lighten;
        border-radius: 10px;
        padding: 4px 10px;
        text-align: center;
        color: #fafcfd;
        display: inline-flex;
        margin-top: 8px;
        @include font-s-bold;
    }

    &Item {
        background: #fff;
        position: absolute;
        width: 240px;
        height: 240px;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include ipad {
            width: 280px;
            height: 280px;
            position: relative;
            top: unset;
            left: unset;
            margin: auto;
            transform: unset;
        }

        &Content {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin: 0;
            padding: 50px 25px;
            @include font-m-bold;
            color: $color-black-lighten;

            img {
                margin-bottom: 20px;
            }
        }
    }
}

.Title {
    font-weight: bold;
    font-size: 24px;
    line-height: 130%;
    text-align: center;
    color: #393a43;
    margin: 0 0 40px;
}

.Progress {
    position: absolute;
    top: 0;
    left: 0;
    circle {
        stroke: #0066ff;
        stroke-width: 5px;
        width: 100%;
        height: 100%;
        transform: rotate(-90deg);
        transform-origin: 50% 50%;
    }
}

.ItemWrapper {
    padding: 0 6px;
}
