@import '~scss/variables';

.Component {
    background: #fff;
}

.Container {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    padding: 130px 40px 56px;

    @include tablet {
        padding-top: 80px;
        padding-right: 0;
        padding-left: 0;

        flex-direction: column;
    }
}

.Nav {
    margin-right: 150px;
    position: sticky;
    top: 100px;
    left: 0;

    @include tablet {
        margin-right: 0;
        margin-bottom: 32px;
        display: flex;
        overflow: auto;
        padding: 0 16px;
        max-width: 100%;

        @include disableScrollbar;
    }

    a {
        font-weight: 600;
        font-size: 20px;
        line-height: 124%;
        color: #2f3a47;
        white-space: nowrap;
        display: block;
        margin-bottom: 16px;

        @include tablet {
            padding: 8px 16px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            background: #e7edf3;
            margin-bottom: 0;
            color: #728ba4;
            margin-right: 8px;

            &:last-child {
                margin-right: 0;
            }
        }

        &.active {
            opacity: 0.3;

            @include tablet {
                background: #0066ff;
                color: #ffffff;
                opacity: 1;
            }
        }
    }
}

.Content {
    @include tablet {
        padding-right: 16px;
        padding-left: 16px;
    }

    section:not(:last-child) {
        margin-bottom: 40px;
        padding-bottom: 40px;
        border-bottom: 1px solid #e4e7ec;
    }

    h1 {
        font-weight: bold;
        font-size: 30px;
        line-height: 124%;
        letter-spacing: -0.03em;
        color: #2f3a47;
        margin: 0 0 4px;
        @include phone {
            font-size: 26px;
            line-height: 140%;
        }
    }

    p {
        font-size: 16px;
        line-height: 155%;
        color: #738192;
        margin: 0 0 32px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    h2 {
        font-weight: bold;
        font-size: 24px;
        line-height: 120%;
        color: #223954;
    }

    ul {
        margin-bottom: 16px;
        li {
            display: block;
            margin-bottom: 4px;
            font-weight: bold;
            font-size: 16px;
            line-height: 150%;
            color: #223954;
            padding: 6px 12px;
            background: rgba(34, 57, 84, 0.1);
            border-radius: 10px;
            width: fit-content;

            &:last-child {
                margin: 0;
            }
        }
    }

    .mb-0 {
        margin-bottom: 16px;
    }
}
.LinkGroup {
    display: flex;
    align-items: center;

    a {
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: #223954;
        padding: 12px 24px;
        background: rgba(34, 57, 84, 0.1);
        border-radius: 10px;

        margin-right: 8px;
    }
}
