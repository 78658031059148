@import '~scss/variables';

.Component {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 24px 40px;
    height: 98px;
    display: flex;
    align-items: center;
    z-index: 1000;
    justify-content: space-between;
    transition: 0.5s;
    transform: translateY(-100%);
    opacity: 0;
    &.show {
        opacity: 1;
        transform: translateY(0);
    }

    &.show-sidebar {
        background: #edeef7;
    }

    @include ipad {
        padding: 20px 24px;
        height: 88px;
    }
}

.LeftSide,
.RightSide {
    // flex-grow: 1;
    flex-basis: 10%;
}

.LeftSide {
    display: flex;
    align-items: center;

    @include ipad {
        align-items: stretch;
        flex-grow: 1;
        justify-content: space-between;
        background: #ffffff;
        box-shadow: 0px 3px 6px rgba(107, 132, 173, 0.1);
        border-radius: 75px;
    }
}

.RightSide {
    display: flex;
    justify-content: flex-end;

    @include ipad {
        display: none;
    }
}

.Logo {
    font-size: 0;
    margin-right: 30px;

    @include ipad {
        margin-right: 0;
        padding-top: 12px;
        padding-bottom: 12px;
        padding-right: 0;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 100px;
            height: auto;
        }
    }
}

.Center {
    display: flex;
    align-items: center;
    flex-basis: 100%;
    justify-content: center;
    position: relative;

    @include ipad {
        display: none;
    }
}

.Nav {
    display: flex;
    background: #fff;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    position: relative;
    z-index: 6;
    box-shadow: 0px 3px 6px rgba(107, 132, 173, 0.1);

    &Wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }

    & > a,
    & > button {
        height: 50px;
        padding: 0;
        @include font-s-semibold;
        color: $color-black;
        border-right: 1px solid rgba(47, 58, 71, 0.06);
        position: relative;
        overflow: hidden;

        & > span {
            padding: 14px 20px;
        }

        &:first-child {
            border-top-left-radius: inherit;
            border-bottom-left-radius: inherit;
        }

        &:last-child {
            margin-right: 0;
            border-top-right-radius: inherit;
            border-bottom-right-radius: inherit;
            border-right: none;
        }

        svg {
            transition: inherit;
            path {
                fill: #2f3a47;
            }
        }

        svg:first-child {
            margin-right: 8px;
        }

        @include hover {
            background: #f8faff;
        }

        &:active {
            background: #eff3fc;
        }

        &::after {
            content: '';
            width: 54px;
            height: 4px;
            border-radius: 2px;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 50%);
            background-color: #0066ff;
            opacity: 0;
            transition: inherit;
        }

        &.active {
            color: #0066ff;

            &::after {
                opacity: 1;
            }

            svg {
                path {
                    fill: #0066ff;
                }
            }
        }

        // .Icon {
        // 	transition: inherit;
        // 	margin-right: 0;
        // 	margin-left: 4px;

        // 	&_rotate {
        // 		transform: rotate(180deg);
        // 	}
        // }
    }
}

.FeatureDropdown {
    position: absolute;
    top: calc(100% + 8px);
    left: 50%;
    width: 440px;
    z-index: 5;
    background: #fff;
    box-shadow: 0px 24px 50px rgba(107, 132, 173, 0.07), 0px 3px 6px rgba(107, 132, 173, 0.1);
    overflow: hidden;
    border-radius: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    transition: 0.2s ease-in-out;
    pointer-events: none;
    opacity: 0;
    transform: translateX(-50%) translateY(-50%);

    &.show {
        pointer-events: unset;
        opacity: 1;
        transform: translateX(-50%) translateY(0);
    }

    &Item {
        padding: 24px;
        display: flex;
        flex-direction: column;
        position: relative;
        text-align: left;
        border-bottom: 1px solid #f4f6f7;
        border-right: 1px solid #f4f6f7;
        font-weight: 600;
        font-size: 14px;
        line-height: 148%;
        color: #2f3a47;

        @include hover {
            background: #f8faff;
        }

        &:active {
            background: #eff3fc;
        }

        &:nth-child(even) {
            border-right: none;
        }

        &:nth-last-child(1),
        &:nth-last-child(2) {
            border-bottom: none;
        }

        img {
            width: 32px;
            height: 32px;
            margin-bottom: 12px;
        }
    }

    &Tag {
        position: absolute;
        top: 20px;
        right: 20px;
        border-radius: 7px;
        padding: 1px 5px;
        font-weight: bold;
        font-size: 12px;
        line-height: 130%;
        color: #ffffff;

        &.blue {
            background: #476aff;
        }

        &.green {
            background: #34cea2;
        }
    }
}

.Btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 148%;
    flex-shrink: 0;
    background: #fff;
    color: #2f3a47;
    box-shadow: 0px 3px 6px rgba(107, 132, 173, 0.1);
    border-radius: 49px;

    & > span {
        padding: 14px 18px;
    }

    @include hover {
        color: #0066ff;
    }

    &:active {
        color: rgba(#0066ff, 0.4);
    }
}

.LanguageSwitcher {
    &Controller {
        width: 49px;
        text-transform: uppercase;

        @include ipad {
            box-shadow: none;
            width: 48px;
            height: 47px;
            border-radius: 0;
            border-top-right-radius: 50%;
            border-left: 1px solid #f4f6f6;
            border-bottom-right-radius: 50%;
        }

        & > span {
            padding: 14px;
        }
    }
}

.BtnSidebar {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    color: #2f3a47;
    width: 48px;
    height: 47px;
    display: none;
    position: relative;
    border-radius: 0;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    padding: 0;
    border-right: 1px solid #f4f6f6;

    @include ipad {
        display: block;
    }
    span {
        display: block;
        position: absolute;
        height: 2px;
        width: 12px;
        background: #2f3a47;
        border-radius: 2px;
        opacity: 1;
        left: 0;
        left: 50%;
        transform: rotate(0deg) translateX(-50%);
        transition: 0.25s ease-in-out;

        &:nth-child(1) {
            top: calc(50% - 1px - 4px);
            transform-origin: left center;
        }

        &:nth-child(2) {
            top: calc(50% - 1px);
            transform-origin: left center;
        }

        &:nth-child(3) {
            top: calc(50% - 1px + 4px);
            transform-origin: left center;
        }
    }

    &.open span {
        &:nth-child(1) {
            transform: rotate(45deg) translateX(-50%);
            top: calc(50% - 1px);
        }

        &:nth-child(2) {
            width: 0%;
            opacity: 0;
        }

        &:nth-child(3) {
            transform: rotate(-45deg) translateX(-50%);
            top: calc(50% - 1px);
        }
    }
}

.Sidebar {
    position: fixed;
    top: 88px;
    height: calc(100% - 88px);
    left: 0;
    width: 100%;
    background: #edeef7;
    transition: 0.5s;
    opacity: 0;
    pointer-events: none;
    z-index: 1000;
    padding: 0 16px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &LinkTitle {
        font-weight: bold;
        font-size: 24px;
        line-height: 130%;
        color: #393a43;
        margin: 0 0 20px;
        display: block;
    }

    nav {
        margin-bottom: 20px;
        overflow: hidden;
        border-radius: 24px;
        // button,
        // a {
        // 	display: flex;
        // 	align-items: center;
        // 	width: 100%;
        // 	background: #ffffff;
        // 	box-shadow: 0px 24px 50px rgba(107, 132, 173, 0.07),
        // 		0px 3px 6px rgba(107, 132, 173, 0.1);
        // 	border-radius: 16px;
        // 	font-weight: 600;
        // 	font-size: 14px;
        // 	line-height: 148%;
        // 	color: #2f3a47;
        // 	text-align: left;
        // 	padding: 16px;
        // 	margin-bottom: 8px;

        // 	&:last-child {
        // 		margin-bottom: 0;
        // 	}

        // 	img,
        // 	svg:first-child {
        // 		width: 24px;
        // 		height: 24px;
        // 		flex-shrink: 0;
        // 		margin-right: 8px;
        // 	}

        // 	span {
        // 		flex-grow: 1;
        // 	}

        // 	svg:last-child {
        // 		transform: rotate(-90deg);
        // 	}
        // }

        a {
            display: flex;
            flex-direction: column;
            background: #fff;
            padding: 24px;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                right: 0;
                top: 50%;
                transform: translate(50%, -50%);
                border-radius: 4px;
                height: 80px;
                background-color: #0066ff;
                width: 4px;
                opacity: 0;
            }

            &:not(:last-child) {
                border-bottom: 1px solid #edeef7;
            }

            b {
                font-weight: bold;
                font-size: 20px;
                line-height: 124%;
                margin-bottom: 6px;
            }

            svg {
                margin-bottom: 6px;
                width: 32px;
                height: 32px;
                transition: inherit;

                path {
                    fill: #2f3a47;
                }
            }

            span {
                white-space: pre-wrap;
                font-size: 16px;
                line-height: 155%;
                color: #738192;
            }

            &.active {
                &::after {
                    opacity: 1;
                }

                b {
                    color: #0066ff;
                }

                svg path {
                    fill: #0066ff;
                }
            }
        }
    }

    &.show {
        pointer-events: unset;
        opacity: 1;
    }
}

.BtnDownload {
    background: #0066ff;
    box-shadow: 0px 4px 16px rgba(0, 102, 255, 0.21);
    border-radius: 75px;

    @include hover {
        background: #2f3a47;
    }

    &:active {
        background: #1c242d;
    }

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 24px;
        position: relative;
        font-weight: 600;
        white-space: nowrap;
        font-size: 14px;

        line-height: 148%;
        color: #ffffff;

        svg {
            flex-shrink: 0;
            margin-right: 8px;
        }
    }
}
