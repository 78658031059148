@import '~scss/variables';

.Slider {
    :global {
        .slick-slide {
            height: unset;
            float: none;
            display: flex !important;
            & > div {
                display: inherit;
                transition: 0.2s;

                & > div {
                    display: inherit !important;
                }
            }

            &:not(.slick-active) > div {
                opacity: 0.4;
            }
        }

        .slick-track {
            display: flex;
        }
    }
}
.SliderDots {
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin-top: 19px;

    :global {
        li.slick-active button::after {
            background: #0066ff;
            border-color: #0066ff;
        }
    }

    li {
        button {
            padding: 5px;
            font-size: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            &::after {
                content: '';
                width: 6px;
                height: 6px;
                border: 2px solid #c7c9d9;
                border-radius: 50%;
                background: transparent;
            }
        }
    }
}
