@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;600;700&display=swap');
@import '~scss/variables';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

html,
body {
    height: 100%;
    font-family: 'Manrope';
    margin: 0;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

input,
select,
textarea {
    border-radius: 0;
    outline: none;
    font-family: inherit;

    &:invalid,
    &:required {
        box-shadow: none; // fix for FF
    }
}

fieldset {
    padding: 0;
    margin: 0;
    border: none;
}

#root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

a,
button {
    cursor: pointer;
    transition: 0.25s linear;
    text-decoration: none;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    outline: none;
    background: transparent;
    border: none;

    & > span {
        outline: none;
    }

    &:disabled {
        cursor: not-allowed;
    }
}
