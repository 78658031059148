@import '~scss/variables';

.Component {
    background: #f8fafd;
    min-height: 100vh;
    padding: 100px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.Content {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    position: relative;
    z-index: 5;

    &::after {
        content: '';
        display: block;
        position: absolute;
        z-index: -1;
        height: 50%;
        width: 100%;
        left: 0;
        top: 50%;
        background: linear-gradient(180deg, rgba(248, 250, 253, 0) 0%, #f8fafd 57.81%);
    }
    &::before {
        content: '';
        display: block;
        position: absolute;
        z-index: -1;
        height: 50%;
        width: 100%;
        left: 0;
        bottom: 50%;
        background: linear-gradient(180deg, rgba(248, 250, 253, 0) 0%, #f8fafd 57.81%);
    }

    h1 {
        font-weight: bold;
        font-size: 58px;
        line-height: 124%;
        text-align: center;
        letter-spacing: -0.03em;
        color: #2f3a47;
        margin: 0 0 24px;

        @include landscape {
            font-size: 30px;
            line-height: 124%;
        }

        span {
            display: block;
            color: #0066ff;
        }
    }

    a {
        @include landscape {
            width: 100%;
            max-width: 320px;
        }
    }
}
